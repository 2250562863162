<template>
  <b-row>
    <b-col cols="3">

      <div class="mb-4 d-flex" style="margin-bottom: 0px !important;">
        <b-button style='border-radius: 0px; flex:1' variant="primary" @click="$router.push('/place')"><font color="white">신청 관리</font></b-button>
        <b-button style='border-radius: 0px; flex:1' variant="secondary" @click="$router.push('/placeBill')"><font color="white">이용 관리</font></b-button>
      </div>
      
      <br/>
      <TreeView :setDong="setDong" :selected="dongSearch == '' ? '전체' : dongSearch"></TreeView>
    </b-col>
    <b-col cols="9">
      <b-card no-body class="mb-4">
        <b-card-body class="p-3">
          <h5 class="card-title mb-0">시설예약 신청 목록 (총 신청 건수 10건)</h5>
        </b-card-body>

        <b-table
          responsive
          class="mb-0 p-3"
          head-variant="light"
          :items="items"
          :current-page="currentPage"
          :per-page="perPage"
          :fields="fields"
        >

        <template #cell(place)="data">
          <a style='font-weight: 700; cursor: pointer;' @click="openStatusChange">
          {{data.item.place}}
          </a>
        </template>

        <template #cell(status)="data">
          <b-button variant="outline-success" v-if="(data.item.status == 1)">확인</b-button>

          <b-button variant="outline-danger" @click="deny(data.item.idx)" v-if="(data.item.status == 0)">거절</b-button>&nbsp;
          <b-button variant="outline-primary" v-if="(data.item.status == 0)">승인</b-button>
        </template>

        <template #cell(now_status)="data">
          <b-badge variant="success" v-if="(data.item.now_status == 0)">요청</b-badge>
          <b-badge variant="danger" v-if="(data.item.now_status == 1)">승인</b-badge>
          <b-badge variant="primary" v-if="(data.item.now_status == 2)">거절</b-badge>
        </template>

        </b-table>

        <div class="row p-3 m-0">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            class="my-0"
          ></b-pagination>
        </div>
      </b-card>
    </b-col>

  </b-row>
  
</template>

<style>
.b-table {
  border: 1px solid #eee
}
</style>

<script>
import TreeView from '../components/apps/TreeView';
export default {
  name: "Place",

  data: () => ({
    page: {
      title: "PaginationTable",
    },
    fields: [
      {
        key: "target",
        label: "신청 세대",
      },
      {
        key: "place",
        label: "신청 시설",
      },
      {
        key: "date",
        label: "신청일",
      },
      {
        key: "date_req",
        label: "사용 요청일",
      },
      {
        key: "now_status",
        label: "상태",
      },
      {
        key: "status",
        label: "사용 승인",
      }
    ],
    items: [
      {idx: "1", target: "102동 1301호", place: "헬스장", date: "22.12.05", date_req: "23.12.31", now_status : 0, status: 0},
      {idx: "2", target: "507동 602호", place: "헬스장", date: "22.12.01", date_req: "23.06.15", now_status : 0, status: 0},
      {idx: "3", target: "302동 1103호", place: "커뮤니티 센터", date: "22.11.28", date_req: "22.11.28 16:00 ~ 17:00", now_status : 1, status: 1},
      {idx: "4", target: "108동 304호", place: "수영장", date: "22.05.01", date_req: "~ 22.05.30 13:00, 매주 월, 수", now_status : 2, status: 1},
      {idx: "5", target: "408동 902호", place: "골프 연습장", date: "22.04.15", date_req: "~ 22.05.15 매주 화 17:00", now_status : 1, status: 1},
      {idx: "6", target: "511동 1504호", place: "골프 연습장", date: "22.03.01", date_req: "22.05.28 매주 목 18:00", now_status : 1, status: 1},
      {idx: "7", target: "503동 202호", place: "커뮤니티 센터", date: "22.02.15", date_req: "22.02.15 17:00 ~ 17:30", now_status : 2, status: 1},
      {idx: "8", target: "506동 701호", place: "커뮤니티 센터", date: "22.02.15", date_req: "22.02.15 17:00 ~ 17:30", now_status : 1, status: 1},
      {idx: "9", target: "208동 1302호", place: "수영장", date: "22.01.13", date_req: "22.02.13 13:00, 매주 월, 수 ,금", now_status : 1, status: 1},
      {idx: "10", target: "106동 103호", place: "헬스장", date: "21.06.01", date_req: "21.08.01", now_status : 1, status: 1},
      {idx: "11", target: "504동 1101호", place: "수영장", date: "21.05.01", date_req: "21.05.01 14:00", now_status : 2, status: 1}
    ],
    dongSearch: "",
    totalRows: 0,
    currentPage: 1,
    perPage: 10,

  }),
  components: {TreeView},
  methods: {
    deny(){
      this.$swal.fire({
        title: '거절 사유 입력',
        html: "<input type='text' class='form-control'></input>",
        showCancelButton: true,
        confirmButtonText: '확인',
        cancelButtonText: '취소',
      })
    },
    openStatusChange(){
      this.$swal.fire({
        title: '골프연습장 시간별 사용인원',
        html: "<center><table style='text-align: center;'><thead><tr><th>요일</th><th>시간</th><th>사용 / 수용인원</th></tr></thead><tbody><tr><td>수</td><td>06:00 ~ 07:00</td><td>75 / 100</td></tr><tr><td>수</td><td>07:00 ~ 08:00</td><td>75 / 100</td></tr><tr><td>수</td><td>08:00 ~ 09:00</td><td>75 / 100</td></tr><tr><td>수</td><td>09:00 ~ 10:00</td><td>75 / 100</td></tr><tr><td>수</td><td>10:00 ~ 11:00</td><td>75 / 100</td></tr><tr><td>수</td><td>11:00 ~ 12:00</td><td>75 / 100</td></tr></tbody></table></center>",
        confirmButtonText: '확인',
      })
    },
    setDong(dong, isChild){
      if(isChild){
        this.dongSearch = dong;
      }
      else{
        this.dongSearch = "";
      }
    }
  },
  mounted() {
    this.totalRows = this.items.length;
  },
};
</script>
